
.App{
  width: 100%;
  height: 100vh;
  /* gap: 1rem; */
}
.App h1{
  font-size: 4rem;
  color: rgba(0,0,0,.25);
}
.button{
  padding: 1rem 2rem;
  background-color: black;
  color: white;
  font-size: 18px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .9rem;
  border-radius: 10px;
  cursor: pointer;
}
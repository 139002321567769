// all photos
.recent-photo-container {
    width: 100%;
    height: 85vh;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    overflow: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
    .photo-container {
        display: flex;
        gap: 1rem;
        gap: 1.2rem;
        width: 100%;
        height: auto;
        flex-wrap: wrap;
        .photo-card {
            width: 300px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            padding: 0.5em;
            border-radius: 10px;
            border: 1px solid grey;
            overflow: hidden;
            gap: 0.4rem;
            .img-title {
                font-size: 18px;
            }

            img {
                // width: 100%;
                max-height: 300px;
                border-radius: 5px;
            }
            .img-delete-btn {
                width: 100%;
                padding: 0.5rem 0;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid red;
                background: red;
                color: white;
                gap: 1rem;
                border-radius: 10px;
                h1 {
                    font-size: 18px;
                }
            }
            .utility-btn {
                font-size: 18px;
                color: white;
            }
        }
    }
}

// all video
.recent-video-container {
    width: 100%;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    overflow: scroll;
    height: 85vh;
    .video-card {
        min-width: 400px;
        width: 45%;
        height: fit-content;
        border: 1px solid black;
        box-shadow: 5px 5px 10px rgba(128, 128, 128, 0.2);
        padding: 1rem;
        border-radius: 1.2rem;
        video {
            height: 100%;
            max-height: 400px;
            width: 100%;
            border-radius: 15px;
        }
        .video-delete-btn {
            width: 100%;
            padding: 0.5rem 0;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid red;
            background: red;
            color: white;
            gap: 1rem;
            border-radius: 10px;
            cursor: pointer;
            h1 {
                font-size: 18px;
            }
        }
    }
}
// all job
.recent-job-container {
    width: 100%;
    height: 85vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.2rem;
    overflow: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
    .job-card-dashboard {
        width: 40%;
        padding: 1rem;
        border-radius: 15px;
        background: rgba(0, 0, 0, 0.03);
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
        align-items: center;
        border: 1px solid grey;
        .dashboard-job-card-image-container {
            width: 100%;
            img {
                width: 100%;
                border-radius: 0.8rem;
            }
        }
        .dashboard-job-card-detail {
            // border: 1px solid red;
            width: 90%;
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            padding-top: 0.5rem;
            border-top: 1px solid grey;
            h4 {
                font-size: 20px;
                color: black;
                text-transform: capitalize;
            }
            h6 {
                font-size: 19px;
                color: grey;
                span {
                    color: black;
                }
            }
            p {
                font-size: 16px;
                color: grey;
            }
            .delete-btn-jobs {
                width: 100%;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 18px;
                font-weight: 600;
                color: white;
                background: red;
                border-radius: 10px;
                border: none;
            }
        }
    }
}

// recent apply

.os {
    overflow: scroll;
}
.oh {
    overflow: hidden;
}
.recent-apply-container {
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    &::-webkit-scrollbar {
        display: none;
    }
}
.apply-header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.2rem 0;
    font-size: 1.2rem;
    font-weight: 600;
    border-bottom: 1px solid grey;
}
.apply-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    // flex-direction: column;
    gap: 1.2rem;
    padding: 1rem;
}
.apply-card {
    display: flex;
    flex-direction: column;
    gap: 0.9rem;
    width: 100%;
    height: auto;
    padding: 1rem;
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
}
.apply-card-img {
    width: 100%;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(128, 128, 128, 0.2);
    border-radius: 1rem;
}
.apply-card-img img {
    height: 100%;
    border-radius: 10px;
}
.apply-card h1 {
    font-size: 18px;
    padding-left: 0.2rem;
    text-transform: capitalize;
}
.apply-card h2 {
    font-size: 17px;
    padding-left: 0.2rem;
    color: grey;
}

.apply-card h3 {
    font-size: 18px;
    color: rgb(29, 116, 255);
}
.apply-card h4 {
    font-size: 18px;
    color: rgb(131, 131, 131);
}
.apply-card .apply-title {
    padding: 0.5rem 1rem;
    font-size: 15px;
    font-weight: 600;
    text-transform: capitalize;
    background: rgba(2, 158, 255, 0.1);
    color: rgb(5, 97, 255);
    border-radius: 5px;
}
.apply-card .apply-field {
    padding: 0.5rem 1rem;
    font-size: 15px;
    font-weight: 600;
    text-transform: capitalize;
    background: rgba(2, 255, 57, 0.1);
    color: rgb(0, 172, 9);
    border-radius: 5px;
}
.dateTime {
    font-size: 16px;
    color: rgb(110, 110, 110);
}
.dateTime span {
    color: black;
}
.delete-btn {
    width: 100%;
    padding: 0.7rem 0;
    color: white;
    background: red;
    border-radius: 10px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 600;
}

.recent-apply-container {
    // width: 100%;
    // height: 80vh;
    // display: flex;
    // &::-webkit-scrollbar {
    //     display: none;
    // }
    .apply-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 1rem;

        // .apply-card {
        //     display: flex;
        //     flex-direction: column;
        //     gap: 0.9rem;
        //     width: 100%;
        //     height: auto;
        //     padding: 1rem;
        //     box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.2);
        //     border-radius: 15px;
        //     .apply-card-img {
        //         width: 100%;
        //         height: 400px;
        //         display: flex;
        //         align-items: center;
        //         justify-content: center;
        //         background: rgba(128, 128, 128, 0.2);
        //         border-radius: 1rem;
        //         .apply-card-img img {
        //             // height: 100%;
        //             width: 100px;
        //             border-radius: 10px;
        //         }
        //     }
        //     .apply-card h1 {
        //         font-size: 18px;
        //         padding-left: 0.2rem;
        //         text-transform: capitalize;
        //     }
        //     .apply-card h2 {
        //         font-size: 17px;
        //         padding-left: 0.2rem;
        //         color: grey;
        //     }
        //     .apply-card h3 {
        //         font-size: 18px;
        //         color: rgb(29, 116, 255);
        //     }
        //     .apply-card h4 {
        //         font-size: 18px;
        //         color: rgb(131, 131, 131);
        //     }
        //     .apply-card .apply-title {
        //         padding: 0.5rem 1rem;
        //         font-size: 15px;
        //         font-weight: 600;
        //         text-transform: capitalize;
        //         background: rgba(2, 158, 255, 0.1);
        //         color: rgb(5, 97, 255);
        //         border-radius: 5px;
        //     }
        //     .apply-card .apply-field {
        //         padding: 0.5rem 1rem;
        //         font-size: 15px;
        //         font-weight: 600;
        //         text-transform: capitalize;
        //         background: rgba(2, 255, 57, 0.1);
        //         color: rgb(0, 172, 9);
        //         border-radius: 5px;
        //     }
        //     .dateTime {
        //         font-size: 16px;
        //         color: rgb(110, 110, 110);
        //     }
        //     .dateTime span {
        //         color: black;
        //     }
        //     .delete-btn {
        //         width: 100%;
        //         padding: 0.7rem 0;
        //         color: white;
        //         background: red;
        //         border-radius: 10px;
        //         border: none;
        //         display: flex;
        //         align-items: center;
        //         justify-content: center;
        //         font-size: 18px;
        //         font-weight: 600;
        //     }
        // }

        .apply-card2 {
            min-width: 300px;
            width: 300px;
            height: 480px;
            // border: 1px solid red;
            border-radius: 1.5rem;
            padding: 1rem;
            display: flex;
            align-items: center;
            flex-direction: column;
            background: white;
            gap: 1rem;
            overflow: hidden;
            .apply-img-container2 {
                width: 100%;
                height: 300px;
                background: rgb(212, 212, 212);
                border-radius: 0.8rem;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    width: 300px;
                    border-radius: 0.8rem;
                }
                h2 {
                    font-size: 20px;
                }
            }
            .card-detail2 {
                width: 100%;
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                gap: 0.2rem;
                h2 {
                    font-size: 20px;
                }
                h4 {
                    width: 280px;
                    overflow: hidden;
                    font-size: 18px;
                    font-weight: 400;
                    color: grey;
                }
                h3{
                    font-size: 20px;
                }
            }
            .apply-card-btn2 {
                width: 100%;
                display: flex;
                gap: 0.8rem;
                .delete-btn2 {
                    width: 100%;
                    padding: 0.7rem 0;
                    color: white;
                    background: red;
                    border-radius: 10px;
                    border: none;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 18px;
                    font-weight: 600;
                    cursor: pointer;
                }
                .view-btn2 {
                    width: 100%;
                    width: 100%;
                    padding: 0.7rem 0;
                    color: rgb(0, 0, 0);
                    background: rgb(220, 220, 220);
                    border-radius: 10px;
                    border: none;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 18px;
                    font-weight: 600;cursor: pointer;
                }
            }
        }
    }
}
.layout-btn {
    color: white;
    position: absolute;
    top: 5%;
    right: 45%;
    transform: translate(-50%,0);
}

// all company upload jobs

.company-job-container {
    width: 100%;
    height: 85vh;
    overflow-y: scroll;
    // border: 1px solid red;
    .upload-detail-card {
        padding: 1.5rem;
        border: 2px solid grey;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
        .detail-section-1 {
            display: flex;
            justify-content: space-between;
            div {
                display: flex;
                flex-direction: column;
                gap: 0.4rem;
                .detail-job-title {
                    font-size: 22px;
                    text-transform: capitalize;
                }
                .request-tag {
                    padding: 0.3rem 1rem;
                    background: rgba(0, 255, 13, 0.2);
                    color: green;
                    font-size: 16px;
                }
                .detail-date {
                    font-size: 18px;
                    font-weight: 400;
                    text-transform: capitalize;
                    display: flex;
                    gap: 0.4rem;
                    align-items: center;
                    .icon {
                        font-size: 14px;
                    }
                }
                .detail-time {
                    font-size: 18px;
                    font-weight: 500;
                    color: grey;
                    text-transform: capitalize;
                    display: flex;
                    align-items: center;
                    gap: 0.4rem;
                    .icon {
                        font-size: 14px;
                    }
                }
            }
        }
        .detail-description {
            font-size: 18px;
            font-weight: 600;
            color: grey;
        }
        .upload-name {
            font-size: 18px;
            text-transform: capitalize;
        }
        .upload-contact {
            display: flex;
            gap: 1rem;
            margin-top: 0.5rem;
            color: blue;
        }
        .upload-delete {
            padding: 0.5rem 1.5rem;
            background: red;
            color: white;
            border: none;
            border-radius: 5px;
            font-size: 18px;
            font-weight: 600;
            width: 200px;
        }
    }
}

// sure pop up
.sure-container {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    // border: 1px solid red;
    position: absolute;
    top: 0%;
    left: 0%;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    .sure-card {
        width: 350px;
        height: fit-content;
        padding: 0.7rem;
        background-color: white;
        border-radius: 1.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 1.2rem;
        img {
            width: 100%;
            border-radius: 1rem;
        }
        p {
            font-size: 18px;
            font-weight: 600;
        }
        .btn-container-sure {
            display: flex;
            align-items: center;
            gap: 1rem;
            width: 100%;
            .delete-btn-sure {
                width: 100%;
                padding: 0.7rem 2rem;
                color: white;
                background: rgb(255, 121, 121);
                border-radius: 10px;
                border: none;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 18px;
                font-weight: 600;
                cursor: pointer;
                transition: 0.3s all;
                &:hover {
                    background: red;
                }
            }
            .cancel-btn-sure {
                width: 100%;
                padding: 0.7rem 2rem;
                color: black;
                background: rgba(107, 107, 107, 0.2);
                border-radius: 10px;
                border: none;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 18px;
                font-weight: 600;
                cursor: pointer;
            }
        }
    }
}

/* @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap'); */

.job-container {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    z-index: 100;
    background: white;
    display: grid;
    grid-template-columns: 2.6fr 9.4fr;
    gap: 1.2rem;
    font-family: "Roboto", sans-serif;
}

.nav {
    width: 100%;
    height: 100%;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
    padding: .8rem 1rem;
}

.back-btn {
    width: 100%;
    padding: 1.2rem .8rem;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    display: flex;
    align-items: center;
    gap: 1.2rem;
    font-size: 18px;
    text-decoration: none;
    color: black;
    font-weight: bolder;
}

.menu-container {
    width: 100%;
    height: 90%;
    /* background: red; */
    display: flex;
    align-items: center;
    justify-content: start;
}

.menu-container ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.menu-container ul .menu-item {
    width: 100%;
    padding: 1.2rem 1.2rem;
    font-size: 18px;
    font-weight: bolder;
    text-decoration: none;
    color: black;
    /* background: rgba(0, 0, 0, 0.1); */
    list-style: none;
    border-radius: 10px;
    display: flex;
    gap: 1.2rem;
    justify-content: space-between;
    border: 1px solid transparent;
}

.menu-container ul .menu-item:hover {
    color: #dfbb67;
    background: black;
}

.main-container {
    display: grid;
    grid-template-rows: 1fr 11fr;
    /* background: url('../../../public/images/logo.jpg');
    backdrop-filter: blur(30px);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; */
}

.header-main-container {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    background: white;
}

.main-container .role-container {
    height: 90vh;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem 0;
    gap: 5rem;
    border-radius: .9rem;
    border: 1px solid red;
    background: url('../../../public/images/logo-2.jpg');
    backdrop-filter: blur(30px);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.job-card {
    width: 50%;
    height: auto;
    background: white;
    border-radius: 1.2rem;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    padding: .5rem;
    box-shadow: 6px 6px 18px rgba(0, 0, 0, 0.2);
    border: 2px solid black;
}
.job-card-image-container{
    width: 100%;
    height: 400px;
    max-height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    overflow: hidden;
}
.job-card-image-container img{
    max-width: 100%;
    height: 100%;
}

.job-card-details{
    display: flex;
    flex-direction: column;
    padding: 0 .8rem;
}
.job-card-details h1{
    font-size: 24px;
    text-transform: capitalize;
    color: black;
}
.job-card-details h2{
    font-size: 18px;
    text-transform: capitalize;
    color: #a3a3a3;
}
.job-card .action-btn{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: .8rem;
    padding: 0 .3rem .3rem .3rem;
}
.action-btn .job-btn{
    background: rgba(0, 0, 0, 0.1);
    width: 20%;
    height: 100%;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
}
.action-btn .apply-btn{
    width: 100%;
    background: black;
    padding: 1rem 0;
    font-size: 18px;
    color: white;
    font-weight: 600;
    border-radius: 10px;
    transition: .4s all;
}
.apply-btn:hover{
    background: transparent;
    color: black;
}
.mobile-job-nav {
    position: absolute;
    top: 1%;
    left: 3%;
    display: flex;
    gap: .8rem;
    display: none;
    width: 95%;
    align-items: center;
    justify-content: space-between;
}
.mobile-job-nav-menu{
    background: white;
    position: absolute;
    left: 0%;
    height: 80vh;
    width: 100%;
    padding: .8rem;
    margin-top: 1rem;
}
@media screen and (max-width:765px) {
    .job-container {
        grid-template-columns: 0 12fr;
        gap: 0;
    }

    .nav {
        padding: 0;
        overflow: hidden;
    }

    .mobile-job-nav {
        display: flex;
    }

    .job-card {
        width: 90%;

    }
}
.gtc120{
    grid-template-columns: "12fr 0fr";
}
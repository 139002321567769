.nav_container {
  width: 90%;
  padding: 0.4rem 2rem;
  z-index: 5;
  background: black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  border-radius: 0 0 20px 20px;
  position: relative;
}
.nav_container .logo_container {
  width: 80px;
  height: 100%;
}
.nav_container .logo_container img {
  width: 100%;
}
.nav_container ul {
  display: flex;
  align-items: center;
}
.nav_container ul .nav_link {
  color: rgba(255, 255, 255, 0.5019607843);
  font-size: 18px;
  font-weight: 600;
  list-style: none;
  padding: 0.6rem 2rem;
  border-radius: 10px;
  border: 2px solid transparent;
  text-decoration: none;
}
.nav_container .menu_icon {
  font-size: 22px;
  color: white;
  display: none;
}
.nav_container .mobile_menu {
  width: 100vw;
  height: auto;
  position: absolute;
  top: 100%;
  right: 0;
  display: none;
  flex-direction: column;
  background: black;
}
.nav_container .mobile_menu .nav_link {
  width: 100%;
  padding: 1.5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: white;
  background: rgb(0, 0, 0);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  border-radius: none;
  text-decoration: none;
}

@media screen and (max-width: 765px) {
  .nav_container {
    width: 100%;
    border-radius: 0;
    padding: 0.4rem 0.8rem;
  }
  .nav_container ul {
    display: none;
  }
  .nav_container .menu_icon {
    display: block;
    font-size: 26px;
  }
  .nav_container .mobile_menu {
    display: flex;
  }
}/*# sourceMappingURL=nav.css.map */
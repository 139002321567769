.side-bar-container{
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,.3);
    backdrop-filter: blur(10px);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.side-bar{
    width: 35%;
    height: 100%;
    background: white;
}
.side-bar-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1.2rem;
}
.side-bar-header h2{
    font-size: 24px;
}
.side-bar-header .close-icon{
    font-size: 24px;
}
.side-bar-header .close-icon:hover{
    color: red;
}
.side-bar-img{
    width: 90%;
    height: 350px;
    background: rgba(0,0,0,.2);
    border-radius: 10px;
    padding: .5rem;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.side-bar-img img{
    height: 100%;
    border-radius: 5px;
}
.side-bar-details{
    margin-top: .6rem;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.side-detail-box{
    display: flex;
    flex-direction: column;
    gap: .2rem;
}
.side-detail-box h4{
    font-size: 18px;
    color: grey;
    font-weight: 500;
}
.side-detail-box h2{
    font-size: 20px;
}
.side-card-btn{
    display: flex;
    gap: .5rem;
    padding: 1rem .5rem
}
.delete-btn2 {
    width: 100%;
    padding: 0.7rem 0;
    color: white;
    background: red;
    border-radius: 10px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
}
.view-btn2 {
    width: 100%;
    width: 100%;
    padding: 0.7rem 0;
    color: rgb(0, 0, 0);
    background: rgb(220, 220, 220);
    border-radius: 10px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 600;cursor: pointer;
}
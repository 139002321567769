.form {
    width: 100%;
    height: 100vh;
    background-color:rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    backdrop-filter: blur(20px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.form .form-card {
    width: 30%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.2rem;
    border-radius: 2rem;
    box-shadow: 8px 8px 15px rgba(0, 0, 0, 0.3);
    background-color: white;
    padding: 1.2rem;
}
.upload-bar{
    width: 90%;
    height: 10px;
    background: black;
    border-radius: 15px;
}
.bar{
    height: 100%;
    background: rgb(9, 255, 0);
    border-radius: 15px;
    transition: .4s all;
}
.form-card .form-inputs {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 90%;
}

.form-inputs input {
    width: 100%;
    height: 50px;
    border: 2px solid grey;
    border-radius: 10px;
    padding: 0 1.2rem;
    font-size: 18px;
}

.add-job-btn {
    padding: .6rem 2.5rem;
    color: white;
    background-color: rgb(29, 116, 255);
    border: none;
    font-size: 18px;
    font-weight: 600;
    border-radius: 10px;
}
.add-job-btn:hover{
    background: red;
}
.cancel-btn {
    padding: .6rem 2.5rem;
    color: rgb(0, 0, 0);
    background-color: rgb(199, 199, 199);
    border: none;
    font-size: 18px;
    font-weight: 600;
    border-radius: 10px;
}
.field-selecter{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    gap: 1.2rem;
}
.field-selecter select{
    width: 60%;
    height: 30px;
}
.field-selecter select option{
    font-size: 18px;
    font-weight: 600;
    color: grey;
    text-transform: capitalize;
}
.pre-details{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 600;
    gap: 1.2rem;
}

@media screen and (max-width:765px) {
    .form .form-card{
        width: 93%;
    }
}
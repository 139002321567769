.footer-container{
    width: 100%;
    margin:0 auto;
    background: black;
    padding: 1.4rem 1.6rem;
    color: white;
    display: flex;
    flex-direction: column;
    gap:.4rem;
}
.footer-wrapper{
    width: 88%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}
.footer-logo{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
}
.footer-logo img{
    width:90px;
}
.footer-wrapper ul{
    display: flex;
    align-items: center;
    gap: 1.2rem;
    list-style: none;
    color: grey;
}
.contact-btn{
    width: 150px;
    height: 46px;
    font-size: 18px;
    color: black;
    font-weight: 600;
    background: white;
    border-radius: 2rem;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}
.contacts{
    display: flex;
    flex-direction: column;
    gap: .6rem;
    align-items: center;
}
.contacts h2{
    font-size: 16px;
}
.contacts p{
    width: 200px;
}
.social-links{
    display: flex;
    gap: 1rem;
    font-size: 28px
}
.social-links a {
    color: white;
}
.social-links a :hover{
    color: #dfbb67  ;
}
.links-container{
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: column;
    gap: .8rem;
    align-items: center;
    justify-content: center;
}
.copyright{
    display: flex;
    align-items: center;
    justify-content: center;
    padding:.6rem;
    border-top: 1px solid grey;
}
.footer-link{
    color: grey;
    text-decoration: none;
    font-size: 18px;
    font-weight: 600;
}
.footer-link:hover{
    color: white;
}
@media screen and (max-width:765px) {
    .footer-wrapper{
        width: 99%;
        flex-direction: column;
        gap: 2rem;
    }
    .copyright{
        margin-top: 1rem;
    }
}
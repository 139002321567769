.home_container{
    width: 100%;
    height: 100vh;
    background: url("../../../public/images/bg-1.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    animation: bg_change 12s linear infinite;
    @media screen and (max-width:765px) {
        height: calc(100vh - 5vh);
    }
    h2{
        font-size: 3vw;
        color: #dfbb67;
        @media screen and (max-width:765px) {
            font-size: 48px;
        }
    }
    .home-btn-container{
        display: flex;
        gap: 1rem;
    }  
}
@keyframes bg_change {
    0%,100%{
        background: url("../../../public/images/bg-1.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    36%{
        background: url("../../../public/images/bg-1.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    38%{
        background: url("../../../public/images/bg-2.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    74%{
        background: url("../../../public/images/bg-2.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    76%{
        background: url("../../../public/images/bg-3.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    98%{
        background: url("../../../public/images/bg-3.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
}

.upload_form_container {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(10px);
    display: flex;
    align-items: center;
    justify-content: center;
    .upload_form {
        width: 40%;
        height: auto;
        padding: 2rem 0;
        background: #ffffff;
        border-radius: 2.3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 1.2rem;
        h2 {
            color: #dfab33;
        }
        h3 {
            color: #808080;
        }
        p {
            font-size: 18px;
            font-weight: 600;
            color: #dfab33;
        }
        .upload-msg{
            font-size:16px;
            color: black;
            text-transform: capitalize;
        }
        .upload-bar{
            width: 80%;
            height: 15px;
            background: black;
            border-radius: 15px;
            .bar{
                height: 100%;
                background: rgb(9, 255, 0);
                border-radius: 15px;
                transition: .4s all;
            }
        }
        .file_input {
            border: 2px solid grey;
            background: transparent;
            width: 70%;
            border-radius: 3rem;
            height: 240px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: transparent;
            &::-webkit-file-upload-button {
                display: none;
            }
            &::before {
                content: "click / drag & drop images";
                font-weight: 600;
                color: grey;
                text-transform: capitalize;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
            }
        }
        .title_input{
            width: 70%;
            padding: .8rem 1.2rem;
            border: 1px solid black;
            border-radius: 15px;
            color: black;
            font-size: 18px;
            font-weight: 600;
            text-transform: capitalize;
        }
        .checkbox {
            display: flex;
            gap: .8rem;
            button{
                width: auto;
                padding: .6rem .8rem;
                font-size: 16px;
                color: rgb(74, 74, 74);
                background: rgba(0, 0, 0, 0.2);

            }
        }
        button {
            width: 150px;
            padding: 0.8rem 0;
            background: black;
            color: white;
            font-size: 18px;
            font-weight: 600;
            border-radius: 10px;
            border: none;
        }
    }
}
@media screen and (max-width: 786px) {
    .upload_form_container {
        .upload_form {
            width: 96%;
            .file_input {
                width: 85%;
            }
        }
    }
}

.dashboard-container-two {
    position: absolute;
    top: 0;
    z-index: 100;
    width: 100%;
    height: 100vh;
    background: rgb(235, 235, 235);
    padding: 1rem;
    display: flex;
    gap: 1rem;
    .side-nav {
        width: 20%;
        background: black;
        border-radius: 1rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 1.5rem;
        overflow: hidden;
        .side-logo {
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                width: 50%;
            }
        }
        ul {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            .navlink-sidenav {
                padding: 0.8rem 1rem;
                color: white;
                text-decoration: none;
            }
        }
    }
    .main-block {
        width: 80%;
        // border: 1px solid black;
        display: flex;
        flex-direction: column;
        .main-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: black;
            padding: 1rem;
            color: white;
            border-radius: 1.2rem;
            h2 {
                font-size: 28px;
            }
            .header-btn {
                display: flex;
                align-items: center;
                gap: 1rem;
                .exit-icon {
                    width: 50px;
                    height: 50px;
                    background: white;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    border: 1px solid transparent;
                    justify-content: center;
                    transition: 0.3s all;
                    &:hover {
                        border: 1px solid black;
                        border: 1px solid black;
                    }
                }
                .add-job-btn {
                    width: 180px;
                    height: 50px;
                    color: white;
                    background-color: rgb(29, 116, 255);
                    border: none;
                    font-size: 18px;
                    font-weight: 600;
                    border-radius: 10px;
                }
                .add-photo-button {
                    width: 180px;
                    height: 45px;
                    color: white;
                    background-color: rgb(29, 116, 255);
                    border: none;
                    font-size: 18px;
                    font-weight: 600;
                    border-radius: 10px;
                }
            }
        }
        .main-section-container {
            width: 100%;
            height: 100%;
            padding: 1rem 0;
        }
    }
}
.minimize-btn{
    width: 60px;
    height: 60px;
    border-radius: 15px;
    background: rgb(0, 106, 255);
    position: absolute;
    bottom: 3.5%;
    left: 2%;
    font-size: 24px;
    color: white;
    border: none;
}
.auth-popup {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    background: white;
    .pop-card {
        width: 30%;
        padding: 1.4rem 2rem;
        border-radius: 1.2rem;
        background: white;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 0.8rem;
        box-shadow: 8px 8px 15px rgba(0, 0, 0, 0.2);
        input {
            width: 90%;
            height: 56px;
            border: 1px solid grey;
            border-radius: 5px;
            padding: 0 1rem;
            font-size: 18px;
            background: transparent;
        }
        button {
            width: 180px;
            height: 46px;
            background: black;
            color: white;
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 2rem;
        }
        p {
            font-size: 14px;
            color: red;
            font-weight: bold;
        }
    }
}
@media screen and (max-width:765px) {
    .dashboard-container-two{
        display: none;
    }
}

.refresh-btn{
    width: 60px;
    height: 60px;
    background: rgb(224, 224, 224);
    color: black;
    font-size: 24px;
    position: absolute;
    right: 2%;
    border-radius: 50%;
    border: 2px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
}
.photo_container {
  width: 100%;
  height: 90vh;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 1.2rem;
}
.photo_container::-webkit-scrollbar {
  display: none;
}
.photo_container .image_container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}
.photo_container .image_container .image-card {
  min-width: 400px;
  width: 45%;
  height: auto;
  padding: 1rem;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  border-radius: 2rem;
  box-shadow: 5px 5px 10px rgba(128, 128, 128, 0.2);
}
.photo_container .image_container .image-card .image-card-container {
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 1.2rem;
  border-bottom: 2px solid grey;
}
.photo_container .image_container .image-card .image-card-container img {
  height: 100%;
  border-radius: 15px;
}
.photo_container .image-header {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.photo_container .image-header .filter-tags {
  padding: 0.7rem 1.2rem;
  font-size: 16px;
  font-weight: 600;
  border-radius: 5px;
  background: rgba(128, 128, 128, 0.2);
  cursor: pointer;
}

.video-container {
  width: 100%;
  display: flex;
  gap: 1rem;
  justify-content: center;
  flex-wrap: wrap;
}
.video-container .video-card {
  min-width: 400px;
  height: -moz-fit-content;
  height: fit-content;
  width: 45%;
  border: 1px solid black;
  box-shadow: 5px 5px 10px rgba(128, 128, 128, 0.2);
  padding: 0.5rem;
  border-radius: 2rem;
}
.video-container .video-card video {
  height: 100%;
  max-height: 400px;
  width: 100%;
  border-radius: 30px;
}
.video-container .video-card .video-title {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
  font-size: 18px;
  font-weight: 600;
  color: #8b8b89;
}/*# sourceMappingURL=photo.css.map */
.apply-form-container {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    backdrop-filter: blur(20px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.apply-card2 {
    width: 90%;
    height: 90%;
    background: white;
    display: flex;
    gap: 2rem;
    border-radius: 1.5rem;
    padding: 1.2rem;
}
.card-section2 {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}
.card-section2 h2{
    font-size: 24px;
}
.tag-apply{
    display: flex;
    align-items: center;
    gap: 1rem;
}
.tag-apply h3{
    padding: .4rem 1.5rem;
    border-radius: 5px;
    background:rgba(98, 255, 0, 0.1);
    color : rgb(64, 153, 0);
}

.form-social-icon{
    display: flex;
    gap: 1.2rem;
    font-size: 28px;
}

.form-social-icon a{
    color: black;
}
.card-form{
    display: flex;
    flex-direction: column;
    gap: 1.4rem;
}
.input-form{
    width: 100%;
    border-bottom:2px solid black ;
    border-top:none ;
    border-left:none ;
    border-right:none ;
    padding: .4rem 0;
    font-size: 18px;
    outline: none;
}

.btn-container{
    width: 100%;
    display: flex;
    gap: 1.2rem;
    margin-top: .5rem;
}
.btn-container .addbtn{
    width: 100%;
    padding: .8rem 0;
    font-size: 18px;
    background: rgb(0, 145, 255);
    color: white;
    font-weight: 600;
    border: none;
    border-radius: 5px;
}
.cancel-btn2{
    background: rgba(0, 0, 0, 0.2);
    color: black;
    width: 100%;
    padding: .8rem 0;
    font-size: 18px;
    font-weight: 600;
    border: none;
    border-radius: 5px;
}
.card-logo-2 {
    width: 60px;
    border-radius: 10px;
}
.img-work {
    width: 60%;
}
.img-work img {
    width: 100%;
    height: 100%;
    border-radius: 1rem;
}

@media screen and (max-width:765px) {
    .apply-form-container{
        .apply-card2{
            height: 78%;
            padding: 1.2rem;
            .card-section{
                width: 100%;
                .tag-apply{
                    h3{
                        padding: .4rem .8rem;
                       font-size: 16px;
                    }
                }
            }
            .img-work{
                display: none;
            }
        }
    }
}